<template>
  <div class="dashboard">
    <div class="sub-wrap">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../assets/icons/dashboard.svg" />
            <h2>{{ $t("dashboard.title") }}</h2>
          </div>
        </div>

        <div v-if="loading">
          <div class="loading-wrap">
            <div class="loading-spinner"></div>
          </div>
        </div>
        <div v-else>
          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="content-wrap">
                    <header class="content-header">
                      <h3>{{ $t("dashboard.ads") }}</h3>
                    </header>
                    <div class="content-body">
                      <p>{{ $t("dashboard.adsDescription") }}</p>
                      <p style="margin-top: 16px">
                        <router-link to="/campaigns" class="button button-ci button-small button-100 button-round">{{ $t("dashboard.adsCreate") }}</router-link>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="content-wrap" :style="devices.length < 0 ? 'background: #4c4c4c; color: #fff' : ''">
                    <header class="content-header" :style="devices.length < 0 ? 'border-bottom: 1px solid #555' : ''">
                      <h3>{{ $t("dashboard.display") }}</h3>
                    </header>
                    <div class="content-body">
                      <p>{{ $t("dashboard.displaySubline") }}</p>
                      <p style="margin-top: 16px">
                        <router-link to="/devices" class="button button-ci button-small button-100 button-round">{{
                          $t("dashboard.displayButton")
                        }}</router-link>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="content-wrap">
                    <header class="content-header">
                      <h3>{{ $t("global.campaigns") }}</h3>
                    </header>
                    <div class="content-body">
                      <p class="body-big">{{ campaigns }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="content-wrap">
                    <header class="content-header">
                      <h3>{{ $t("global.balance") }}</h3>
                    </header>
                    <div class="content-body">
                      <p class="body-big">{{ user.balance_formatted }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div class="content-wrap">
                <header class="content-header">
                  <h3>OOHLEMON TV-Stick bestellen</h3>
                </header>
                <div class="content-body">
                  <p>Bestelle jetzt unseren Plug &amp; Play-TV-Stick für eine einfache Verwendung des OOHLEMON-Systems</p>
                  <button
                    style="padding:8px 12px;border:0;border-radius:4px;font-size:1em;width:100%;margin-top:10px"
                    id="checkout-button-price_1HlewkJw2oOYuWo6LV9igdJe"
                    role="link"
                    type="button"
                  >
                    Jetzt zur Bestellung ›
                  </button>
                </div>
              </div>
              -->
            </div>
            <div class="col-12">
              <div class="content-wrap">
                <header class="content-header">
                  <h3>{{ $t("dashboard.start") }}</h3>
                </header>
                <div class="content-body">
                  <p v-html="$t('dashboard.startDescription')"></p>
                  <ol class="getting-started">
                    <li>
                      <router-link to="/balance/setup_intents/new">{{ $t("dashboard.startPay") }}</router-link>
                    </li>
                    <li>
                      <router-link to="/devices/new">{{ $t("dashboard.startDisplay") }}</router-link>
                    </li>
                    <li>
                      <router-link to="/playlists">{{ $t("dashboard.startPlaylist") }}</router-link>
                    </li>
                    <li>
                      <router-link to="/schedules">{{ $t("dashboard.startTime") }}</router-link>
                    </li>
                    <li>
                      <router-link to="/devices">{{ $t("dashboard.startDevices") }}</router-link>
                    </li>
                  </ol>
                  <p>{{ $t("dashboard.startCta") }}</p>
                </div>
              </div>
            </div>
            <div class="col-24">
              <div class="content-wrap">
                <header class="content-header">
                  <h3>{{ $t("global.infos") }}</h3>
                </header>
                <div class="content-body flex-wrap">
                  <div v-for="article in articles" v-bind:key="article.uuid" class="announcement">
                    <h3>{{ article.title }}</h3>
                    <p class="date">{{ article.date_formatted }}</p>
                    <p v-html="article.body"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.getting-started {
  padding: 0;
  list-style: none;
  counter-reset: my-awesome-counter;
  padding: 13px 0;

  li {
    counter-increment: my-awesome-counter;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;

    a {
      color: black;
      &:hover {
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  li::before {
    content: counter(my-awesome-counter);
    border: 2px solid var(--ci-color);
    background: var(--ci-color);
    color: var(--ci-contrast-color);
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 100%;
    padding: 10px;
    align-items: center;
    margin-right: 15px;
    font-weight: bold;
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px !important;
  }
}
</style>

<script>
import axios from "axios";
import store from "../store";

export default {
  name: "dashboard",
  data() {
    return {
      loading: true,
      campaigns: "",
      dashboard: "",
      balance: "",
      articles: [],
      devices: [],
      user: {},
      campaign_count: "",
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.loading = false;
          this.campaigns = response.data.campaigns.length;
          /*
          var that = this;
          setTimeout(function () {
            that.stripe_stick();
          }, 1000);
          */
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/dashboard", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.dashboard = response.data.campaigns;
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/devices", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.devices = response.data.devices;
          this.loading = false;
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/articles", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.articles = response.data.articles;
        });
    },
    stripe_stick() {
      const Stripe = window.Stripe;
      var stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

      var checkoutButton = document.getElementById("checkout-button-price_1HlewkJw2oOYuWo6LV9igdJe");
      if (checkoutButton) {
        checkoutButton.addEventListener("click", function () {
          /*
           * When the customer clicks on the button, redirect
           * them to Checkout.
           */
          stripe
            .redirectToCheckout({
              lineItems: [{ price: "price_1HlewkJw2oOYuWo6LV9igdJe", quantity: 1 }],
              mode: "payment",
              successUrl: "https://www.oohlemon.com/success",
              cancelUrl: "https://www.oohlemon.com/canceled",
            })
            .then(function (result) {
              if (result.error) {
                /*
                 * If `redirectToCheckout` fails due to a browser or network
                 * error, display the localized error message to your customer.
                 */
                var displayError = document.getElementById("error-message");
                displayError.textContent = result.error.message;
              }
            });
        });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
